import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useResolvedPath } from 'react-router-dom';
import Home from './pages/Home';
import * as utilities from './utilities';
import Nav from './components/Nav/Nav';
import ToastPanel from './components/Toasts/ToastPanel';
import Config from './pages/Config';
import SafetyApp from './pages/SafetyApp';
import SafetyAppEntries from './pages/SafetyAppEntries';
import DailyReport from './pages/DailyReport';
import DailyReportEntries from './pages/DailyReportEntries';
import Permissions from './pages/Permissions';
import Users from './pages/Users';

const App = () => {
  const [userObj, setUserObj] = useState({});
  const [randomKey, setRandomKey] = useState(Math.random());
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  let logoClickCB = null;

  const history = useNavigate();

  const loginUser = (obj) => {
    setUserObj(obj);
    localStorage.setItem('accessToken', obj.accessToken);
    localStorage.setItem('sessionGUID', obj.sessionGUID);
    localStorage.setItem('endTimestamp', new Date(obj.endTimestamp + "Z").getTime());
    localStorage.setItem('username', obj.username);
    localStorage.setItem('user', `${obj.firstName} ${obj.lastName}`);
    localStorage.setItem('email', obj.username);
    localStorage.setItem('permissions', JSON.stringify(obj.permissions));
    localStorage.setItem('userObj', JSON.stringify(obj));
    setRandomKey(Math.random());
  }

  useEffect(() => {
    if (isLoggingOut) {
      history('/');
      setIsLoggingOut(false);
    }
  }, [isLoggingOut]);

  const handleLogoClick = () => {
    logoClickCB();
  }

  const handleSetLogoClick = (cb) => {
    logoClickCB = (cb);
  }

  const logout = async () => {
    const sessionGUID = utilities.getSessionGUID();
    const rememberUsername = localStorage.getItem('rememberUsername') != null ? localStorage.getItem('rememberUsername') : null;
    localStorage.clear()
    if (rememberUsername != null) {
      localStorage.setItem('rememberUsername', rememberUsername);
    }
    setIsLoggingOut(true);
    setRandomKey(Math.random());
    fetch('/app/logout/' + sessionGUID, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      }
    })
  }

  const links = [
    {
      url: '/ams-concrete/daily-report',
      link: 'AMS Concrete Daily Report',
      authenticated: true,
      permissionGUID: '8b412ffe-e9e6-41bf-ad80-eee57630a267',
      exact: true,
      module: <DailyReport
        logo="/logo-amstephens-concrete.png"
        title="AMS Concrete Daily Report"
        config="config-amstephens-concrete-daily-report"
        configLink="/api/daily-report/configuration/ams-concrete"
        submitLink="/api/daily-report/submit/ams-concrete"
        onLogoClick={handleLogoClick}
      />,
    },
    {
      url: '/ams-concrete/daily-report/admin',
      link: 'AMS Concrete Daily Report Submissions',
      authenticated: true,
      permissionGUID: '88bd9a07-e296-4d77-a608-ccfdc290edfd',
      exact: true,
      small: true,
      module: <DailyReportEntries
        logo="/logo-amstephens-concrete.png"
        title="AMS Concrete Daily Report"
        entriesLink="/api/daily-report/entries/ams-concrete"
        updateLink="/api/daily-report/entries/"
        onLogoClick={handleLogoClick}
      />,
    },
    {
      url: '/ams-concrete/daily-report/config',
      link: 'AMS Concrete Daily Report Configuration',
      authenticated: true,
      permissionGUID: '03f4bae6-5d4e-4490-9bdf-20574e54b6c4',
      exact: true,
      small: true,
      module: <Config
        logo="/logo-amstephens-concrete.png"
        title="AMS Concrete Daily Report"
        config="config-amstephens-concrete-daily-report"
        configLink="/api/daily-report/configuration/ams-concrete"
        submitLink="/api/daily-report/submit/ams-concrete"
        onLogoClick={handleLogoClick}
      />,
    },
    {
      url: '/ams-concrete/safety-app',
      link: 'AMS Concrete Safety App',
      authenticated: true,
      permissionGUID: '59466d4f-87bd-4293-8982-12fb8f167911',
      exact: true,
      module: <SafetyApp
        logo="/logo-amstephens-concrete.png"
        title="AMS Concrete Safety App"
        config="config-amstephens-concrete-safety-app"
        configLink="/api/safety-app/configuration/ams-concrete"
        submitLink="/api/safety-app/submit/ams-concrete"
        onLogoClick={handleLogoClick}
      />,
    },
    {
      url: '/ams-concrete/safety-app/admin',
      link: 'AMS Concrete Safety App Submissions',
      authenticated: true,
      permissionGUID: '8bbe52f2-e2d8-44ad-8131-6134e1bd096f',
      exact: true,
      small: true,
      module: <SafetyAppEntries
        logo="/logo-amstephens-concrete.png"
        title="AMS Concrete Safety App"
        entriesLink="/api/safety-app/entries/ams-concrete"
        updateLink="/api/safety-app/entries/"
        onLogoClick={handleLogoClick}
      />,
    },

    {
      url: '/ams-construction/daily-report',
      link: 'AMS Construction Daily Report',
      authenticated: true,
      permissionGUID: '8928fcf6-d902-4773-897e-bfa77e371c73',
      exact: true,
      module: <DailyReport
        logo="/logo-amstephens-construction.png"
        title="AMS Construction Daily Report"
        config="config-amstephens-construction-daily-report"
        configLink="/api/daily-report/configuration/ams-construction"
        submitLink="/api/daily-report/submit/ams-construction"
        onLogoClick={handleLogoClick}
      />,
    },
    {
      url: '/ams-construction/daily-report/admin',
      link: 'AMS Construction Daily Report Submissions',
      authenticated: true,
      permissionGUID: '88467808-5d50-494c-9884-d510c95dff79',
      exact: true,
      small: true,
      module: <DailyReportEntries
        logo="/logo-amstephens-construction.png"
        title="AMS Construction Daily Report"
        entriesLink="/api/daily-report/entries/ams-construction"
        updateLink="/api/daily-report/entries/"
        onLogoClick={handleLogoClick}
      />,
    },
    {
      url: '/ams-construction/daily-report/config',
      link: 'AMS Construction Daily Report Configuration',
      authenticated: true,
      permissionGUID: '845792ac-9f1a-4faa-b4c2-3b2da67d2069',
      exact: true,
      small: true,
      module: <Config
        logo="/logo-amstephens-construction.png"
        title="AMS Construction Daily Report"
        config="config-amstephens-construction-daily-report"
        configLink="/api/daily-report/configuration/ams-construction"
        submitLink="/api/daily-report/submit/ams-construction"
        onLogoClick={handleLogoClick}
      />,
    },
    {
      url: '/ams-construction/safety-app',
      link: 'AMS Construction Safety App',
      authenticated: true,
      permissionGUID: 'bd375527-aee4-4456-afe5-dcd0ac844585',
      exact: true,
      module: <SafetyApp
        logo="/logo-amstephens-construction.png"
        title="AMS Construction Safety App"
        config="config-amstephens-construction-safety-app"
        configLink="/api/safety-app/configuration/ams-construction"
        submitLink="/api/safety-app/submit/ams-construction"
        onLogoClick={handleLogoClick}
      />,
    },
    {
      url: '/ams-construction/safety-app/admin',
      link: 'AMS Construction Safety App Submissions',
      authenticated: true,
      permissionGUID: '28318dc8-b0d8-46c6-b9b9-12350ea14509',
      exact: true,
      small: true,
      module: <SafetyAppEntries
        logo="/logo-amstephens-construction.png"
        title="AMS Construction Safety App"
        entriesLink="/api/safety-app/entries/ams-construction"
        updateLink="/api/safety-app/entries/"
        onLogoClick={handleLogoClick}
      />,
    },
    {
      url: '/admin/users',
      link: 'Users',
      authenticated: true,
      permissionGUID: '841fa389-0fcd-47ac-929f-c0c075b02cba',
      exact: true,
      module: <Users
        logo="/logo-amstephens-construction.png"
        onLogoClick={handleLogoClick}
      />,
    },
    {
      url: '/admin/permissions',
      link: 'Permissions',
      authenticated: true,
      permissionGUID: 'ab869767-c9e4-405c-b33e-c52019c7b733',
      exact: true,

      module: <Permissions
        logo="/logo-amstephens-construction.png"
        onLogoClick={handleLogoClick}
      />,
    },


  ];

  const handleLinkClick = (url) => {
    history(url);
  };

  return (
    <div id={Math.random().toString()}>
      <div id="appNav">
        <Nav
          links={links.filter((link) => utilities.checkPermission(link.permissionGUID))}
          loginUser={loginUser}
          logout={logout}
          onClick={handleLinkClick}
          handleSetLogoClick={handleSetLogoClick}
        />
      </div>
      <Routes>
        <Route
          Component={() => <Home
            links={links.filter((link) => utilities.checkPermission(link.permissionGUID))}
            loginUser={loginUser}
            logout={logout}
            onLogoClick={handleLogoClick}
            logo="/logo-amstephens-construction.png" />}
          exact={true}
          path="/"
        />
        {links
          .filter((link) => utilities.checkPermission(link.permissionGUID))
          .map((link) => (
            <Route
              key={link.url}
              exact={link.exact}
              Component={() => link.module}
              path={`${link.url}`}
            />
          ))}
      </Routes>
      <div className="toastPanel align-bottom">
        <ToastPanel addCallback={utilities.addCallback} log={utilities.log} info={utilities.info} />
      </div>
    </div>
  );
};

export default App;
