import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

import "./SafetyAppPreview.css"

const SafetyAppPreview = (props) => {

    const showVar = (str) => {
        return str === '' || str == null ? <>&nbsp;</> : str;
    }

    return (
        <div id="">
            <Container>
                <Row className='m-4 text-center'>
                    <Col>
                        <Button onClick={showVar(props.hidePreview)} color="secondary">Close</Button>
                    </Col>
                </Row>
            </Container>
            <div id="safetyAppPreviewContainer" className="page-container">
                <div
                    id="html"
                    className="page-container"
                    style={{
                        padding: 25,
                        backgroundColor: "#fff",
                        width: 720,
                        margin: "0 auto",
                        boxShadow: "3px 3px 10px rgba(0,0,0,.5)"
                    }}
                >
                    <div
                        className="page"
                        style={{ pageBreakAfter: "always", width: "auto", margin: "auto" }}
                    >
                        <table
                            width="100%"
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                            id="header"
                            style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="mailingAddress"
                                        style={{ verticalAlign: "middle", width: "25%", fontSize: 9 }}
                                    >
                                        <p style={{ fontSize: 10, marginTop: 7 }}>
                                            Mailing Address:
                                            <br />
                                            <span
                                                className="uppercase"
                                                style={{ textTransform: "uppercase" }}
                                            >
                                                P.O. Box 1867
                                                <br />
                                                Lodi, CA 95241
                                            </span>
                                        </p>
                                        <p style={{ fontSize: 10, marginTop: 7 }}>
                                            Physical Address:
                                            <br />
                                            <span
                                                className="uppercase"
                                                style={{ textTransform: "uppercase" }}
                                            >
                                                1717 S. Stockton St.
                                                <br />
                                                Lodi, CA 95240
                                            </span>
                                        </p>
                                    </td>
                                    <td
                                        id="logoArea"
                                        style={{
                                            verticalAlign: "middle",
                                            width: "50%",
                                            textAlign: "center",
                                            fontSize: 9
                                        }}
                                    >
                                        <img
                                            src={props.logo}
                                            alt="Logo"
                                            id="logo"
                                            style={{ height: 80 }}
                                        />
                                        <br />
                                        California Contractors License# 404723
                                    </td>
                                    <td
                                        id="phoneArea"
                                        style={{
                                            verticalAlign: "middle",
                                            width: "25%",
                                            textAlign: "right",
                                            fontSize: 10
                                        }}
                                    >
                                        Lodi (209) 333-0136
                                        <br />
                                        Fax (209) 333-0993
                                        <br />
                                        www.amstephens.net
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h1
                            style={{ fontSize: 30, textDecoration: "underline", textAlign: "center", fontWeight: 700 }}
                        >
                            Daily Safety Check List
                        </h1>
                        <table
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                            id="job-area"
                            style={{ width: "90%", margin: "0 auto", fontSize: 18 }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="Date-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "10%",
                                            textAlign: "right",
                                            paddingRight: "2%",
                                            fontSize: 18
                                        }}
                                    >
                                        Date:
                                    </td>
                                    <td
                                        id="Date-field"
                                        className="textfield"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "30%",
                                            fontSize: 18
                                        }}
                                    >
                                        {showVar(props.Date)}
                                    </td>
                                    <td className="spacer" style={{ verticalAlign: "bottom" }}>
                                        &nbsp;
                                    </td>
                                    <td
                                        id="JobName-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "15%",
                                            textAlign: "right",
                                            paddingRight: "2%",
                                            fontSize: 18
                                        }}
                                    >
                                        Job Name:
                                    </td>
                                    <td
                                        id="JobName-field"
                                        className="textfield"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "30%",
                                            fontSize: 18
                                        }}
                                    >
                                        {showVar(props.JobName)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            cellSpacing={0}
                            cellPadding={0}
                            id="a-questions"
                            style={{ width: "50%", marginLeft: "10%", fontSize: 14, marginTop: 10 }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="a-questions-title"
                                        style={{ verticalAlign: "bottom", width: "60%" }}
                                    >
                                        &nbsp;
                                    </td>
                                    <td
                                        id="a-questions-yes"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "18%",
                                            textTransform: "uppercase"
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td className="spacer" style={{ verticalAlign: "bottom" }}>
                                        &nbsp;
                                    </td>
                                    <td
                                        id="a-questions-no"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "18%",
                                            textTransform: "uppercase"
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>1. Hard Hats</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionA1) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionA1) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>2. Safety Vest</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionA2) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionA2) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>3. Safety Glasses</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionA3) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionA3) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                        4. Equipment Back-Up Alarms
                                    </td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionA4) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionA4) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>5. Equipment ARB Stickers</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionA5) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionA5) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>6. Equipment Damage</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionA6) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionA6) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>7. First Aid Kit On-Site</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionA7) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionA7) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                        8. Fire Extinguisher On-Site
                                    </td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionA8) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionA8) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>9. Work Boots</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionA9) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionA9) ? 'X' : ''}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                            id="foreman-signature-area"
                            style={{ width: "50%", marginLeft: "50%", fontSize: 14 }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="ForemansSignature-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "50%",
                                            textAlign: "right"
                                        }}
                                    >
                                        Foreman's Signature:
                                    </td>
                                    <td
                                        id="ForemansSignature-field"
                                        className="textfield"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "50%"
                                        }}
                                    >
                                        {props.ForemanSignature && <img
                                            src={showVar(props.ForemanSignature)}
                                            alt="Foreman's Signature"
                                            className='signature'
                                        />}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                            id="daily-comments-area"
                            style={{ width: "100%", fontSize: 14 }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="DailyComments-label"
                                        style={{ verticalAlign: "top", width: "25%", textAlign: "left" }}
                                    >
                                        Daily Comments on Safety:
                                    </td>
                                    <td
                                        id="DailyComments-field"
                                        className="textfield-noborder"
                                        style={{
                                            verticalAlign: "top",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "75%",
                                            paddingLeft: "5%",
                                            textAlign: "left",
                                            height: 40
                                        }}
                                    >
                                        {showVar(props.SafetyComments)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p style={{ fontSize: 14, marginTop: 7 }}>
                            Daily Heat Illness Prevention: (Each employee must read and sign this
                            section daily)
                        </p>
                        <table
                            cellSpacing={0}
                            cellPadding={0}
                            id="b-questions"
                            style={{ width: "90%", marginLeft: "10%", fontSize: 14, marginTop: 10 }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="b-questions-title"
                                        style={{ verticalAlign: "bottom", width: "75%" }}
                                    >
                                        &nbsp;
                                    </td>
                                    <td
                                        id="b-questions-yes"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "10%",
                                            textTransform: "uppercase"
                                        }}
                                    >
                                        Yes
                                    </td>
                                    <td className="spacer" style={{ verticalAlign: "bottom" }}>
                                        &nbsp;
                                    </td>
                                    <td
                                        id="b-questions-no"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "10%",
                                            textTransform: "uppercase"
                                        }}
                                    >
                                        No
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                        1. Are there provisions for water on the job site?
                                    </td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionB1) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionB1) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                        2. Do you know where your access to shade is?
                                    </td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionB2) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionB2) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                        3. Do you know what to do during a heat wave?
                                    </td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionB3) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionB3) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                        4. Are you informed of the A.M.S. high heat procedures?
                                    </td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionB4) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionB4) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                        5. Are you informed of A.M.S. heat acclimation procedures?
                                    </td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionB5) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionB5) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                        6. Are you informed of the A.M.S. Emergency response rules?
                                    </td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionB6) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionB6) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                        7. Are you informed of how A.M.S. handles a sick employee?
                                    </td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionB7) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionB7) ? 'X' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                        8. Are you informed of the A.M.S. employee and supervisory training?
                                    </td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {showVar(props.QuestionB8) ? 'X' : ''}
                                    </td>
                                    <td style={{ verticalAlign: "bottom" }}>&nbsp;</td>
                                    <td
                                        className="textfield yesno"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textAlign: "center"
                                        }}
                                    >
                                        {!showVar(props.QuestionB8) ? 'X' : ''}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p style={{ fontSize: 14, marginTop: 7 }}>
                            If you have answered <u>yes</u> to the above questions please sign below.
                            If you have answered <u>no</u> to the above questions, A.M.S. wlil take
                            every precaution to make sure you understand the correct answer to each
                            question. With that stated you will not be allowed to begin work each day
                            until you completely understand each of the above questions.{" "}
                        </p>
                        <table
                            cellSpacing={5}
                            cellPadding={0}
                            id="employee-signature-area"
                            style={{ width: "100%", borderSpacing: 5, borderCollapse: 0 }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        className="textfield"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "33%",
                                            height: 30
                                        }}
                                    >
                                        {props.EmployeeSignature1 && <img
                                            src={showVar(props.EmployeeSignature1)}
                                            alt="Employee Signature 1"
                                            className='signature'
                                        />}
                                    </td>
                                    <td
                                        className="textfield"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "33%",
                                            height: 30
                                        }}
                                    >
                                        {props.EmployeeSignature2 && <img
                                            src={showVar(props.EmployeeSignature2)}
                                            alt="Employee Signature 2"
                                            className='signature'
                                        />}
                                    </td>
                                    <td
                                        className="textfield"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "33%",
                                            height: 30
                                        }}
                                    >
                                        {props.EmployeeSignature3 && <img
                                            src={showVar(props.EmployeeSignature3)}
                                            alt="Employee Signature 3"
                                            className='signature'
                                        />}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        className="textfield"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "33%",
                                            height: 30
                                        }}
                                    >
                                        {props.EmployeeSignature4 && <img
                                            src={showVar(props.EmployeeSignature4)}
                                            alt="Employee Signature 4"
                                            className='signature'
                                        />}
                                    </td>
                                    <td
                                        className="textfield"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "33%",
                                            height: 30
                                        }}
                                    >
                                        {props.EmployeeSignature5 && <img
                                            src={showVar(props.EmployeeSignature5)}
                                            alt="Employee Signature 5"
                                            className='signature'
                                        />}
                                    </td>
                                    <td
                                        className="textfield"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "33%",
                                            height: 30
                                        }}
                                    >
                                        {props.EmployeeSignature6 && <img
                                            src={showVar(props.EmployeeSignature6)}
                                            alt="Employee Signature 6"
                                            className='signature'
                                        />}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        className="textfield"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "33%",
                                            height: 30
                                        }}
                                    >
                                        {props.EmployeeSignature7 && <img
                                            src={showVar(props.EmployeeSignature7)}
                                            alt="Employee Signature 7"
                                            className='signature'
                                        />}
                                    </td>
                                    <td
                                        className="textfield"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "33%",
                                            height: 30
                                        }}
                                    >
                                        {props.EmployeeSignature8 && <img
                                            src={showVar(props.EmployeeSignature8)}
                                            alt="Employee Signature 8"
                                            className='signature'
                                        />}
                                    </td>
                                    <td
                                        className="textfield"
                                        style={{
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "33%",
                                            height: 30
                                        }}
                                    >
                                        {props.EmployeeSignature9 && <img
                                            src={showVar(props.EmployeeSignature9)}
                                            alt="Employee Signature 9"
                                            className='signature'
                                        />}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            id="equipment-repair-area"
                            cellSpacing={0}
                            cellPadding={0}
                            style={{ width: "100%", fontSize: 14 }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="EquipmentRepair-label"
                                        style={{ verticalAlign: "top", width: "25%", textAlign: "left" }}
                                    >
                                        Equipment Repair:
                                    </td>
                                    <td
                                        id="EquipmentRepair-field"
                                        className="textfield-noborder"
                                        style={{
                                            verticalAlign: "top",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "75%",
                                            paddingLeft: "5%",
                                            textAlign: "left",
                                            height: 40
                                        }}
                                    >
                                        {showVar(props.RepairNeeds)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div>

    )
}

export default SafetyAppPreview;