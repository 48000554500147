import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import * as utilities from '../../utilities';

const Nav = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        props.handleSetLogoClick(() => {
            setDropdownOpen(!dropdownOpen);
        })
    })

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleMenu} direction='down'>
            <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                {/* <div id="dropdownToggle" ></div> */}
            </DropdownToggle>
            <DropdownMenu dark className='mt-5 py-2'>
                {props.links.map((link, linkIndex) => {
                    if (utilities.checkPermission(link.permissionGUID)) {
                        return (
                            <DropdownItem
                                key={link.url}
                                // activeClassName="navbar__link_xs--active"
                                className={"navbar__link_xs m-0"+ (link.small || linkIndex === 0 ? "" : ' border-top border-secondary')}
                            >
                                <NavLink
                                    className={"py-1 px-1 text-white" + (link.small ? " navSmall ms-2 fw-normal" : '')}
                                    to={link.url}
                                    onClick={() => { if (link.cb != null) link.cb(); toggleMenu(); }}
                                >{link.link}</NavLink>
                            </DropdownItem>
                        );
                    } else {
                        return null;
                    }
                })}
                <DropdownItem
                    className="navbar__link_xs my-0 border-top border-secondary"
                >
                    <NavLink
                        className="py-1 px-1 text-primary "
                        to="/"
                        onClick={() => { props.logout(); }}
                    >Logout</NavLink>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown >
    );
};

export default Nav;
