import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Label, Input, Button } from 'reactstrap';
import CanvasModal from '../components/Canvas/CanvasModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import * as utilities from '../utilities';

const SafetyApp = (props) => {
  const [user, setUser] = useState(localStorage.getItem('user') != null ? localStorage.getItem('user') : '');
  const [email, setEmail] = useState(localStorage.getItem('email') != null ? localStorage.getItem('email') : '');
  const [employeeSignature, setEmployeeSignature] = useState(1);

  const [stateVariables, setStateVariables] = useState(localStorage.getItem('stateVariables') != null ? JSON.parse(localStorage.getItem('stateVariables')) : {});

  const [entries, setEntries] = useState(localStorage.getItem('entries') != null ? JSON.parse(localStorage.getItem('entries')) : []);

  const generateRandomHex = () => {
    const randomHex = Math.floor(Math.random() * 0xFFFFFFFF).toString(16).toUpperCase();
    return randomHex.padStart(8, '0');
  }

  const dailySafetyChecklist = [
    { id: 'QuestionA1', title: '1. Hard Hats' },
    { id: 'QuestionA2', title: '2. Safety Vest' },
    { id: 'QuestionA3', title: '3. Safety Glasses' },
    { id: 'QuestionA4', title: '4. Equipment Back Up Alarms' },
    { id: 'QuestionA5', title: '5. Equipment ARB Stickers' },
    { id: 'QuestionA6', title: '6. Equipment Damage' },
    { id: 'QuestionA7', title: '7. First Aid Kit on Site' },
    { id: 'QuestionA8', title: '8. Fire Extinguisher on Site' },
    { id: 'QuestionA9', title: '9. Work Boots' },
  ];

  const dailyHeatIllnessPrevention = [
    { id: 'QuestionB1', title: '1. Are there provisions for water on the job site?' },
    { id: 'QuestionB2', title: '2. Do you know where your access to shade is?' },
    { id: 'QuestionB3', title: '3. Do you know what to do during a heat wave?' },
    { id: 'QuestionB4', title: '4. Are you informed of the A.M.S. high heat procedures?' },
    { id: 'QuestionB5', title: '5. Are you informed of A.M.S. heat acclimation procedures?' },
    { id: 'QuestionB6', title: '6. Are you informed of the A.M.S. Emergency response rules?' },
    { id: 'QuestionB7', title: '7. Are you informed of how A.M.S. handles a sick employee?' },
    { id: 'QuestionB8', title: '8. Are you informed of the A.M.S. employee and supervisory training?' },
  ];

  const initializeStateVariables = (force) => {
    if (stateVariables[props.config] == null || force) {
      const initialState = {
        Date: new Date().toISOString().substring(0, 10),
        JobName: '',
        QuestionA1: false,
        QuestionA2: false,
        QuestionA3: false,
        QuestionA4: false,
        QuestionA5: false,
        QuestionA6: false,
        QuestionA7: false,
        QuestionA8: false,
        QuestionA9: false,
        QuestionB1: false,
        QuestionB2: false,
        QuestionB3: false,
        QuestionB4: false,
        QuestionB5: false,
        QuestionB6: false,
        QuestionB7: false,
        QuestionB8: false,
        ForemanSignature: '',
        EmployeeSignature1: '',
        EmployeeSignature2: '',
        EmployeeSignature3: '',
        EmployeeSignature4: '',
        EmployeeSignature5: '',
        EmployeeSignature6: '',
        EmployeeSignature7: '',
        EmployeeSignature8: '',
        EmployeeSignature9: '',
        SafetyComments: '',
        RepairNeeds: '',
        Name: '',
        Email: '',
        _TransactionCode: generateRandomHex(),
        _Timestamp: null,
        _Submitted: false
      };
      setStateVariables({ [props.config]: initialState });
      let obj = {};
      if (localStorage.getItem('stateVariables') != null) {
        obj = JSON.parse(localStorage.getItem('stateVariables'));
      };
      obj[props.config] = initialState;
      localStorage.setItem('stateVariables', JSON.stringify(obj))
    }
  };

  const handleInputChange = (key, e) => {
    // Use the spread operator to create a new state object
    let tempStateVariables = { ...stateVariables[props.config] };
    tempStateVariables[key] = e.target.value;
    setStateVariables((prev) => {
      let obj = { ...prev };
      obj[props.config][key] = e.target.value;
      obj[props.config] = { ...obj[props.config] }
      return {
        ...obj
      }
    }
    );
  };

  const saveEntry = () => {
    let obj = {};
    if (localStorage.getItem('entries') != null) {
      obj = JSON.parse(localStorage.getItem('entries'));
    };
    if (obj[props.config] == null) obj[props.config] = [];
    stateVariables[props.config]['_Timestamp'] = (new Date().getTime()) / 1000;
    stateVariables[props.config]['_User'] = user;
    stateVariables[props.config]['_Email'] = email;
    obj[props.config].push(Object.entries(stateVariables[props.config]).reduce((acc, [key, val]) => {
      if ((val != null && val !== '') || String(key).substring(0, 1) === '_') acc[key] = val;
      return acc;
    }, {}));
    localStorage.setItem('entries', JSON.stringify(obj));
    setEntries(obj);
    initializeStateVariables(true);
  }

  const submitEntries = async () => {
    entries[props.config] != null && entries[props.config].filter(entry => !entry['_Submitted']).forEach(async entry => {
      const response = await fetch(props.submitLink, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + utilities.getAccessToken()
        },
        body: JSON.stringify(entry)
      })
      if (response.ok) {
        utilities.addToast('Submission successful', 5, 'success');
        const result = await response.json();
        let obj = { ...entries };
        const entryIndex = obj[props.config].findIndex(entry => entry['_TransactionCode'] === result.transactionCode && !entry['_Submitted']);
        if (entryIndex > -1) {
          obj[props.config][entryIndex]['_Submitted'] = true;
          setEntries(obj);
          localStorage.setItem('entries', JSON.stringify(obj));
        }
      } else {
        utilities.addToast('Submission unsuccessful, however the data is being stored on your device and will be uploaded once connected.', 5, 'danger')
      }
    })
  }

  const toggleQuestion = (key) => {
    let tempStateVariables = { ...stateVariables[props.config] };
    tempStateVariables[key] = !tempStateVariables[key];
    setStateVariables((prev) => {
      let obj = { ...prev };
      obj[props.config][key] = tempStateVariables[key];
      obj[props.config] = { ...obj[props.config] }
      return {
        ...obj
      }
    }
    );
  }

  useEffect((e) => {
    initializeStateVariables();
  }, []);

  useEffect((e) => {
    localStorage.setItem('user', user)
  }, [user]);

  useEffect((e) => {
    submitEntries();
  }, [entries]);

  useEffect((e) => {
    localStorage.setItem('email', email)
  }, [email]);

  useEffect((e) => {
    let obj = {};
    if (localStorage.getItem('stateVariables') != null) {
      obj = JSON.parse(localStorage.getItem('stateVariables'));
    };
    obj = { ...obj, ...stateVariables };
    localStorage.setItem('stateVariables', JSON.stringify(obj))
  }, [stateVariables]);

  return (
    <>
      {stateVariables[props.config] != null && <div className='tablet px-2'>
        <Form>
          <Row className='m-0 p-0  align-items-center '>
            <Col xs={4} className='m-0 p-0 pe-3'>
              <Row>
                <Col className='m-0'>
                  <img id="logo" src={props.logo} alt={props.title} onClick={props.onLogoClick} />
                </Col>
              </Row>
              <Row>
                <Col xs={5} className='m-0 pe-1' id="userEmail">
                  <Input
                    bsSize='sm'
                    className='border-primary p-1'
                    id="user"
                    name="user"
                    placeholder="User"
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                  />
                </Col>
                <Col xs={5} className='m-0 pe-3 ps-0' id="userEmail">
                  <Input
                    bsSize='sm'
                    className='border-primary p-1'
                    id="email"
                    name="email"
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
                {entries[props.config] != null && entries[props.config].filter(entry => !entry['_Submitted']).length > 0 && <Col xs={2} className='m-0 pe-3 ps-0 align-self-center'>
                  <div id="entriesCount">{entries[props.config].filter(entry => !entry['_Submitted']).length}</div>
                </Col>}
              </Row>
            </Col>
            <Col className='m-0 p-0'>
              <Row className='row-cols-lg-auto g-3 align-items-center mt-2 mb-0'>

                <Col className='m-0 pr-0'>
                  <Label
                    for="Date">
                    Date
                  </Label>
                </Col>
                <Col className='w-25 m-0'>
                  <Input
                    // bsSize='lg'
                    className='border-dark'
                    type="date"
                    id="Date"
                    name="Date"
                    value={stateVariables[props.config].Date}
                    onChange={(e) => handleInputChange(e.target.name, e)}
                  />
                </Col>
                <Col className='m-0'>
                  <Label for="JobName">
                    Job Name
                  </Label>
                </Col>
                <Col className='flex-grow-1 m-0'>
                  <Input
                    // bsSize='lg'
                    className='border-dark'
                    id="JobName"
                    name="JobName"
                    value={stateVariables[props.config].JobName}
                    onChange={(e) => handleInputChange(e.target.name, e)}
                  />
                </Col>
              </Row>

            </Col>
          </Row>

          <Row className='align-items-start mt-2 mb-0 px-0 py-2 bg-primary' id="safetyChecklists">
            <Col md={5} className='ps-5 mx-0 fs-5 fw-bold'>Daily Safety Checklist</Col>
            <Col className='ps-5 mx-0 fs-5 fw-bold'>Daily Heat Illness Prevention <span className='finePrint'>Each employee to read and sign this section daily</span></Col>
          </Row>

          <Row className='align-items-start mt-0 mb-0 pb-2 bg-black text-white' id="safetyChecklists">
            <Col md={5}>
              {dailySafetyChecklist.map(item => (
                <Row
                  key={item.id}
                  className='my-1 row-cols-lg-auto g-3 align-items-center'
                >
                  <Col className='m-0'>
                    <Button
                      onClick={() => toggleQuestion(item.id)}
                      className='faButton'
                      outline={!stateVariables[props.config][item.id]}
                      color={stateVariables[props.config][item.id] ? "success" : "primary"}
                    >
                      <FontAwesomeIcon icon={stateVariables[props.config][item.id] ? faCheck : faTimes} />
                    </Button>
                  </Col>
                  <Col
                    className='m-0 fw-bold fs-6 pointer'
                    onClick={() => toggleQuestion(item.id)}
                  >
                    {item.title}
                  </Col>
                </Row>
              ))}

            </Col>

            <Col>
              {dailyHeatIllnessPrevention.map(item => (
                <Row
                  key={item.id}
                  className='my-1 row-cols-lg-auto g-3 align-items-center'
                >
                  <Col className='m-0'>
                    <Button
                      onClick={() => toggleQuestion(item.id)}
                      className='faButton'
                      outline={!stateVariables[props.config][item.id]}
                      color={stateVariables[props.config][item.id] ? "success" : "primary"
                      }>
                      <FontAwesomeIcon icon={stateVariables[props.config][item.id] ? faCheck : faTimes} />
                    </Button>
                  </Col>
                  <Col className='m-0 fw-bold fs-6 pointer'
                    onClick={() => toggleQuestion(item.id)}
                  >
                    {item.title}
                  </Col>
                </Row>
              ))}

              <Row className='my-2'>
                <Col className='finePrint'>
                  If you have answered yes to the above questions please sign below. If you have answered no to these questions A.M.S.
                  will take every precaution to make sure you understand the correct answer to each question. With that stated you
                  will not be allowed to begin work each day until you completely understand each of the above questions.
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className='bg-black text-white'>
            <Col md={5}>
              <Row className='my-1'>
                <Col className='col-lg-auto m-0 pe-0 position-relative'>
                  {stateVariables[props.config].ForemanSignature !== '' &&
                    <img className="signatureImage" src={stateVariables[props.config].ForemanSignature} alt="Foreman Signature" />}
                  <CanvasModal
                    arrkey="ForemanSignature"
                    saveimage={setStateVariables}
                    config={props.config}
                  >
                    <Input
                      className='border-primary bg-black text-white'
                      style={{height: '60px'}}
                      id="ForemanSignature"
                      name="ForemanSignature"
                      readOnly={true}
                      placeholder="Foreman's Signature"
                    />
                  </CanvasModal>
                </Col>
              </Row>
              <Row className='my-1'>
                <Col>
                  <Input
                    // bsSize='lg'
                    className='border-primary bg-black text-white'
                    type="textarea"
                    rows={3}
                    id="safetyComments"
                    name="SafetyComments"
                    placeholder='Daily Comments on Safety'
                    value={stateVariables[props.config].SafetyComments}
                    onChange={(e) => handleInputChange(e.target.name, e)}
                  />
                </Col>
              </Row>
            </Col>
            <Col >
              <Row className='my-1'>
                <Col md={4} className='pe-1'>
                  <Input
                    type="select"
                    className='border-primary bg-black text-white'
                    id="EmployeeSignatureInput"
                    name="EmployeeSignatureInput"
                    value={employeeSignature}
                    onChange={(e) => setEmployeeSignature(e.target.value)}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(item => (
                      <option key={item} value={item}>Employee {item}</option>
                    ))}
                  </Input>
                </Col>
                <Col md={7} className='col-lg-auto m-0 ps-1 pe-0 position-relative'>
                  {stateVariables[props.config][`EmployeeSignature${employeeSignature}`] !== '' &&
                    <img
                      className="signatureImage"
                      src={stateVariables[props.config][`EmployeeSignature${employeeSignature}`]}
                      alt={`Employee ${employeeSignature} Signature`}
                    />}
                  <CanvasModal
                    arrkey={`EmployeeSignature${employeeSignature}`}
                    saveimage={setStateVariables}
                    config={props.config}
                  >
                    <Input
                      className='border-primary bg-black text-white'
                      style={{height: '60px'}}
                      id="EmployeeSignature"
                      name="EmployeeSignature"
                      readOnly={true}
                      placeholder={`Employee ${employeeSignature}'s Signature`}
                    />
                  </CanvasModal>
                </Col>
              </Row>
              <Row className='my-1 align-items-end'>
                <Col md={9}>
                  <Input
                    // bsSize='lg'
                    className='border-primary bg-black text-white'
                    type="textarea"
                    rows={3}
                    id="RepairNeeds"
                    name="RepairNeeds"
                    placeholder='Equipment Repair Needs'
                    value={stateVariables[props.config].RepairNeeds}
                    onChange={(e) => handleInputChange(e.target.name, e)}
                  />
                </Col>
                <Col className='text-center'>
                  <Button
                    color="success"
                    className='px-4'
                    onClick={saveEntry}
                    disabled={stateVariables[props.config].ForemanSignature === ''}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

        </Form >

      </div >}
    </>
  );
};

export default SafetyApp;