import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Table, Input, Button, Label, FormGroup } from 'reactstrap';
import ConfirmButton from '../components/ConfirmButton/ConfirmButton';
import * as utilities from '../utilities';

const Permissions = (props) => {

  const blankPermission = {
    permissionGUID: '',
    permission: '',
    description: '',
    active: true
  }

  const [permissions, setPermissions] = useState([]);
  const [editPermissionGUID, setEditPermissionGUID] = useState(null);
  const [editPermission, setEditPermission] = useState(blankPermission)

  const getPermissions = async () => {
    const response = await fetch('/api/permissions/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      }
    })
    if (response.ok) {
      const result = await response.json();
      setPermissions(result);
    } else {
      utilities.addToast('Unable to load permissions', 5, 'danger');
    }
  };

  const handleDeletePermission = async () => {
    const response = await fetch('/api/permissions/' + editPermissionGUID, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      }
    })
    if (response.ok) {
      utilities.addToast('Permission deleted', 5, 'success');
      setEditPermissionGUID(null);
      setEditPermission(blankPermission);
      getPermissions();
    } else {
      utilities.addToast('Unable to delete permission', 5, 'danger');
    }
  };

  const handleSave = async () => {
    const response = await fetch('/api/permissions/' + editPermissionGUID, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      },
      body: JSON.stringify(editPermission)
    })
    if (response.ok) {
      utilities.addToast('Permission updated', 5, 'success');
      setEditPermissionGUID(null);
      setEditPermission(blankPermission);
      getPermissions();
    } else {
      utilities.addToast('Unable to update permission', 5, 'danger');
    }
  }

  const handleAdd = async () => {
    const response = await fetch('/api/permissions/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      },
      body: JSON.stringify(blankPermission)
    })
    if (response.ok) {
      const result = await response.text();
      setEditPermissionGUID(result);
      setEditPermission(blankPermission);
      getPermissions();
    } else {
    }
  }

  const onEditPermission = (permissionGUID) => {
    const permissionIndex = permissions.findIndex(permission => permission.permissionGUID === permissionGUID);
    if (permissionIndex === -1) return;
    setEditPermission({
      permissionGUID: permissionGUID,
      permission: permissions[permissionIndex].permission,
      description: permissions[permissionIndex].description,
      active: permissions[permissionIndex].active === 1
    });
    setEditPermissionGUID(permissionGUID);
  }

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <>
      {<div className='page px-2'>
        <Row className='m-0 p-0 align-items-end '>
          <Col xs={4} className='m-0 p-0 pe-3'>
            <Row>
              <Col className='m-0'>
                <img id="logo" src="/logo-amstephens.png" alt="AMStephens" onClick={props.onLogoClick} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <Table className='table-light'>
              <thead>
                <tr>
                  <th className='py-4' width="40%">Permission</th>
                  <th className='py-4' width="40%">Description</th>
                  <th className='py-4' width="20%">Active</th>
                </tr>
              </thead>
              <tbody>
                {permissions.map(permission => (<Fragment key={permission.permissionGUID}>
                  {permission.permissionGUID === editPermissionGUID ?
                    <tr>
                      <td colSpan={5}>
                        <Row className='row-cols-lg-auto g-2 my-2 align-items-end'>
                          <Col>
                            <FormGroup>
                              <Label>
                                Permission
                                <Input
                                  style={{ minWidth: 400 }}
                                  name="permission"
                                  value={editPermission.permission}
                                  onChange={(e) => setEditPermission({ ...editPermission, [e.target.name]: e.target.value })}
                                />
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>
                                Description
                                <Input
                                  style={{ minWidth: 400 }}
                                  name="description"
                                  value={editPermission.description}
                                  onChange={(e) => setEditPermission({ ...editPermission, [e.target.name]: e.target.value })}
                                />
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>
                                <Input
                                  name="active"
                                  type="checkbox"
                                  className='me-2'
                                  checked={editPermission.active}
                                  onChange={(e) => setEditPermission({ ...editPermission, [e.target.name]: !editPermission[e.target.name] })}
                                />
                                Active
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className='align-items-end py-3'>
                          <Col className='text-end pe-4'>
                            <Button
                              color="secondary"
                              className='ms-2'
                              onClick={() => {
                                setEditPermission(blankPermission);
                                setEditPermissionGUID(null)
                              }}
                            >
                              Cancel
                            </Button>
                            <ConfirmButton
                              color="danger"
                              className='mx-4'
                              onClick={handleDeletePermission}
                            >
                              Delete
                            </ConfirmButton>
                            <Button
                              color="success"
                              className='ms-2'
                              onClick={handleSave}
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                    : <tr key={permission.permissionGUID} className='pointer' onClick={() => onEditPermission(permission.permissionGUID)}>
                      <td className='py-3'>{permission.permission}</td>
                      <td className='py-3'>{permission.description}</td>
                      <td className='py-3'>{permission.active === 1 ? "Yes" : "No"}</td>
                    </tr>}
                </Fragment>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              color="primary"
              className='ms-2'
              onClick={handleAdd}
            >
              Add Permission
            </Button>
          </Col>
        </Row>
      </div >}
    </>
  );
};

export default Permissions;
