import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Input, Label } from 'reactstrap';
import ConfirmButton from '../ConfirmButton/ConfirmButton';

import "./DailyReportPreview.css"

const DailyReportPreviewEdit = (props) => {

    const [entry, setEntry] = useState(props.entry);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isAutomaticallyCalculating, setIsAutomaticallyCalculating] = useState(true);
    const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false);

    const showVar = (str) => {
        return str === '' || str == null ? '' : str;
    }

    const onBlur = (e) => {
        // console.log('onblur', e.target.name, e.target.value)
        let tempObj = { ...entry.data };
        tempObj[e.target.name] = parseFloat(String(e.target.value).replace(/[^0-9.-]+/g, '')) > 0 ? parseFloat(String(e.target.value).replace(/[^0-9.-]+/g, '')).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        }) : e.target.value;
        setEntry({ ...entry, data: tempObj });
    }

    const onChange = (e) => {
        // console.log('onchange', e.target.name, e.target.value)
        let tempObj = { ...entry.data };
        tempObj[e.target.name] = e.target.value;
        let match, amount, numberToSave, regex;
        if (String(e.target.name).indexOf('Labor') > -1 && String(e.target.name).indexOf('Amount') === -1) {
            regex = /Labor[A-Za-z]+(\d{1,2})/g;
            while ((match = regex.exec(String(e.target.name))) !== null) {
                numberToSave = match[1];
                amount =
                    (
                        (Number(tempObj[`LaborReg${numberToSave}`]) || 0) *
                        (Number(tempObj[`LaborRateReg${numberToSave}`]) || 0)
                    ) +
                    (
                        (Number(tempObj[`LaborOT${numberToSave}`]) || 0) *
                        (Number(tempObj[`LaborRateOT${numberToSave}`]) || 0)
                    ) +
                    (
                        (Number(tempObj[`LaborDT${numberToSave}`]) || 0) *
                        (Number(tempObj[`LaborRateDT${numberToSave}`]) || 0)
                    );
                tempObj[`LaborAmount${numberToSave}`] = (amount > 0) ? amount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }) : '';
            }
        } else if (String(e.target.name).indexOf('EquipmentRentals') > -1 && String(e.target.name).indexOf('Amount') === -1) {
            regex = /EquipmentRentals[A-Za-z]+(\d{1,2})/g;
            while ((match = regex.exec(String(e.target.name))) !== null) {
                numberToSave = match[1];
                amount =
                    (
                        (Number(tempObj[`EquipmentRentalsDay${numberToSave}`]) || 0) *
                        (Number(tempObj[`EquipmentRentalsRate${numberToSave}`]) || 0)
                    );
                tempObj[`EquipmentRentalsAmount${numberToSave}`] = (amount > 0) ? amount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }) : '';
            }
        } else if (String(e.target.name).indexOf('Hauling') > -1 && String(e.target.name).indexOf('Amount') === -1) {
            regex = /Hauling[A-Za-z]+(\d{1,2})/g;
            while ((match = regex.exec(String(e.target.name))) !== null) {
                numberToSave = match[1];
                amount =
                    (
                        (Number(tempObj[`HaulingLoads${numberToSave}`]) || 0) *
                        (Number(tempObj[`HaulingRate${numberToSave}`]) || 0)
                    ) +
                    (
                        (Number(tempObj[`HaulingTons${numberToSave}`]) || 0) *
                        (Number(tempObj[`HaulingRate${numberToSave}`]) || 0)
                    ) +
                    (
                        (Number(tempObj[`HaulingHours${numberToSave}`]) || 0) *
                        (Number(tempObj[`HaulingRate${numberToSave}`]) || 0)
                    );
                tempObj[`HaulingAmount${numberToSave}`] = (amount > 0) ? amount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }) : '';
            }
        } else if (String(e.target.name).indexOf('SuppliesAndMaterials') > -1 && String(e.target.name).indexOf('Amount') === -1) {
            regex = /SuppliesAndMaterials[A-Za-z]+(\d{1,2})/g;
            while ((match = regex.exec(String(e.target.name))) !== null) {
                numberToSave = match[1];
                amount =
                    (
                        (Number(tempObj[`SuppliesAndMaterialsQuantity${numberToSave}`]) || 0) *
                        (Number(tempObj[`SuppliesAndMaterialsPrice${numberToSave}`]) || 0)
                    );
                tempObj[`SuppliesAndMaterialsAmount${numberToSave}`] = (amount > 0) ? amount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }) : '';
            }
        } else if (String(e.target.name).indexOf('Amount') > -1) {
            tempObj[e.target.name] = e.target.value; /* (parseFloat(e.target.value) > 0) ? parseFloat(e.target.value).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            }) : '';*/
        }
        if (isAutomaticallyCalculating) {
            let totalAmount = 0;
            for (let key in tempObj) {
                if (tempObj.hasOwnProperty(key) && key.includes("Amount")) {
                    let value = parseFloat(tempObj[key].replace('$', '').replace(',', ''));
                    if (!isNaN(value)) {
                        totalAmount += value;
                    }
                }
            }
            tempObj.Total = (totalAmount > 0) ? totalAmount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            }) : '';
        }
        setEntry({ ...entry, data: tempObj });
    }

    useEffect(() => {
        if (isLoaded) setIsSaveButtonVisible(true);
        setIsLoaded(true);
    }, [entry])

    return (
        <div id="">
            <div id={props.isRotated ? "dailyReportPreviewContainerRotate" : "dailyReportPreviewContainer"} className="page-container">
                <Container className="closeButton">
                    <Row className='m-4 text-start'>
                        <Col>
                            <Label>
                                <Input
                                    type="checkbox"
                                    checked={isAutomaticallyCalculating}
                                    onClick={() => setIsAutomaticallyCalculating(!isAutomaticallyCalculating)}
                                />
                                Automatically Calculate Grand Total
                            </Label>

                        </Col>
                        <Col className='text-end'>
                            {isSaveButtonVisible ? <>
                                <Button
                                    onClick={() => props.onUpdate(entry.entryId, entry.data, () => setIsSaveButtonVisible(false))}
                                    color="success"
                                    className='me-2'
                                >
                                    Save
                                </Button>
                                <ConfirmButton
                                    onClick={props.hidePreview}
                                    color="secondary"
                                    message="You have unsaved changes. Do you wish to continue?"
                                >
                                    Close
                                </ConfirmButton>
                            </> :
                                <Button
                                    onClick={props.hidePreview}
                                    color="secondary"
                                >
                                    Close
                                </Button>
                            }
                        </Col>
                    </Row>
                </Container>
                <div
                    id="html"
                    className="page-container"
                    style={{
                        padding: 25,
                        width: 720,
                        margin: "0 auto",
                        boxShadow: "3px 3px 10px rgba(0,0,0,.5)"
                    }}
                >
                    <div
                        className="page"
                        style={{ pageBreakAfter: "always", width: "auto", margin: "auto" }}
                    >
                        <table
                            cellSpacing={0}
                            cellPadding={0}
                            id="header"
                            style={{ border: "0 none", width: "100%" }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="logoArea"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "50%",
                                            textAlign: "left",
                                            fontSize: 9
                                        }}
                                    >
                                        <img
                                            src={props.logo}
                                            alt={props.title}
                                            id="logo"
                                            style={{ height: 80 }}
                                        />
                                    </td>
                                    <td
                                        id="titleArea"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "77%",
                                            textAlign: "right",
                                            whiteSpace: "nowrap",
                                            float: "right !important"
                                        }}
                                    >No. {props.entry.entryId}
                                        <br />
                                        <h1 style={{ fontSize: 30, textAlign: "right", fontWeight: "bold", margin: 0 }}>
                                            Daily Field Report
                                        </h1>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                            id="job-area"
                            style={{
                                border: "0 none",
                                width: "100%",
                                margin: "8px auto",
                                fontSize: 14
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="JobNumber-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "5%",
                                            textAlign: "left"
                                        }}
                                    >
                                        Job#
                                    </td>
                                    <td
                                        id="JobNumber-field"
                                        className="textfield center"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "15%"
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="JobNumber"
                                            onChange={onChange}
                                            value={showVar(entry.data.JobNumber)}
                                        />
                                        {/* <Input
    className="change-textfield"
    name="JobNumber"
    onChange={onChange}
    value={showVar(entry.data.JobNumber)}
/> */}
                                    </td>
                                    <td className="spacer" style={{ verticalAlign: "bottom" }}>
                                        &nbsp;
                                    </td>
                                    <td
                                        id="JobName-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "15%",
                                            textAlign: "right"
                                        }}
                                    >
                                        Job Name
                                    </td>
                                    <td
                                        id="JobName-field"
                                        className="textfield center"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "40%"
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="JobName"
                                            onChange={onChange}
                                            value={showVar(entry.data.JobName)}
                                        />
                                    </td>
                                    <td className="spacer" style={{ verticalAlign: "bottom" }}>
                                        &nbsp;
                                    </td>
                                    <td
                                        id="Date-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "7%",
                                            textAlign: "right"
                                        }}
                                    >
                                        Date
                                    </td>
                                    <td
                                        id="Date-field"
                                        className="textfield center"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "13%"
                                        }}
                                    >
                                        <Input
                                            type="date"
                                            className="change-textfield"
                                            name="Date"
                                            onChange={onChange}
                                            value={showVar(entry.data.Date)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                            id="description-area"
                            style={{
                                border: "0 none",
                                width: "100%",
                                margin: "8px auto",
                                fontSize: 14
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="DescriptionOfWork-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "20%",
                                            textAlign: "left"
                                        }}
                                    >
                                        Description of Work
                                    </td>
                                    <td
                                        id="DescriptionOfWork-field"
                                        className="textfield center"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "57%"
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="DescriptionOfWork"
                                            onChange={onChange}
                                            value={showVar(entry.data.DescriptionOfWork)}
                                        />
                                    </td>
                                    <td className="spacer" style={{ verticalAlign: "bottom" }}>
                                        &nbsp;
                                    </td>
                                    <td
                                        id="County-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "7%",
                                            textAlign: "right"
                                        }}
                                    >
                                        County
                                    </td>
                                    <td
                                        id="County-field"
                                        className="textfield center"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "13%"
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="County"
                                            onChange={onChange}
                                            value={showVar(entry.data.County)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            border={0}
                            cellSpacing={0}
                            cellPadding={0}
                            id="billTo-area"
                            style={{
                                border: "0 none",
                                width: "100%",
                                margin: "8px auto",
                                fontSize: 14
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="BillTo-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "7%",
                                            textAlign: "left"
                                        }}
                                    >
                                        Bill To
                                    </td>
                                    <td
                                        id="BillTo-field"
                                        className="textfield center"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "65%"
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="BillTo"
                                            onChange={onChange}
                                            value={showVar(entry.data.BillTo)}
                                        />
                                    </td>
                                    <td className="spacer" style={{ verticalAlign: "bottom" }}>
                                        &nbsp;
                                    </td>
                                    <td
                                        id="RFCO-label"
                                        style={{
                                            verticalAlign: "bottom",
                                            width: "11%",
                                            textAlign: "right"
                                        }}
                                    >
                                        RFCO
                                    </td>
                                    <td
                                        id="RFCO-field"
                                        className="textfield center"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "17%"
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="RFCO"
                                            onChange={onChange}
                                            value={showVar(entry.data.RFCO)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            id="labor"
                            cellPadding={0}
                            cellSpacing={0}
                            style={{
                                border: "0 none",
                                borderRight: "1px solid #000",
                                borderBottom: "3px solid #000",
                                width: "100%"
                            }}
                        >
                            <tbody>
                                <tr>
                                    <th
                                        id="LaborCostCodes"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "10%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Cost Codes
                                    </th>
                                    <th
                                        id="LaborName"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "14%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Name
                                    </th>
                                    <th
                                        id="LaborTime"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "8%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Time
                                    </th>
                                    <th
                                        id="LaborEquipmentOperated"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "11%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Equipment Operated
                                    </th>
                                    <th
                                        id="LaborEquipmentNumber"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Equip. #
                                    </th>
                                    <th
                                        id="LaborReg"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "5%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Reg.
                                    </th>
                                    <th
                                        id="LaborRateReg"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "6%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Rate
                                    </th>
                                    <th
                                        id="LaborOT"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "5%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        O.T.
                                    </th>
                                    <th
                                        id="LaborRateOT"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "6%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Rate
                                    </th>
                                    <th
                                        id="LaborDT"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "5%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        D.T.
                                    </th>
                                    <th
                                        id="LaborRateDT"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "6%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Rate
                                    </th>
                                    <th
                                        id="LaborAmount"
                                        className="bold"
                                        data-calculation="([LaborReg]*[LaborRateReg])+([LaborOT]*[LaborRateOT])+([LaborDT]*[LaborRateDT])"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "bold !important",
                                            textAlign: "center",
                                            fontSize: 11,
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000"
                                        }}
                                    >
                                        Amount
                                    </th>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes1"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes1)}
                                        />
                                        {/* <Input
    className="change-textfield"
    name="LaborCostCodes1"
    onChange={onChange}
    value={showVar(entry.data.LaborCostCodes1)}
/> */}
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName1"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName1)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime1"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime1)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated1"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated1)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber1"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber1)}
                                        />
                                        {/* <Input
    className="change-textfield"
    name="LaborEquipmentNumber1"
    onChange={onChange}
    value={showVar(entry.data.LaborEquipmentNumber1)}
/> */}
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg1"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg1)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg1"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg1)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT1"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT1)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT1"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT1)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT1"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT1)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT1"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT1)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount1"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount1)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes2"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes2)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName2"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName2)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime2"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime2)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated2"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated2)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber2"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber2)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg2"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg2)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg2"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg2)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT2"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT2)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT2"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT2)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT2"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT2)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT2"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT2)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount2"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount2)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes3"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes3)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName3"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName3)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime3"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime3)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated3"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated3)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber3"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber3)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg3"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg3)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg3"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg3)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT3"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT3)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT3"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT3)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT3"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT3)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT3"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT3)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount3"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount3)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes4"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes4)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName4"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName4)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime4"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime4)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated4"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated4)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber4"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber4)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg4"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg4)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg4"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg4)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT4"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT4)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT4"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT4)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT4"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT4)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT4"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT4)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount4"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount4)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes5"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes5)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName5"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName5)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime5"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime5)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated5"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated5)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber5"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber5)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg5"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg5)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg5"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg5)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT5"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT5)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT5"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT5)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT5"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT5)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT5"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT5)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount5"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount5)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes6"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes6)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName6"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName6)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime6"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime6)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated6"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated6)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber6"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber6)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg6"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg6)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg6"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg6)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT6"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT6)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT6"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT6)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT6"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT6)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT6"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT6)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount6"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount6)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes7"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes7)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName7"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName7)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime7"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime7)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated7"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated7)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber7"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber7)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg7"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg7)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg7"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg7)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT7"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT7)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT7"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT7)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT7"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT7)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT7"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT7)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount7"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount7)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes8"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes8)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName8"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName8)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime8"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime8)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated8"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated8)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber8"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber8)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg8"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg8)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg8"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg8)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT8"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT8)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT8"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT8)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT8"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT8)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT8"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT8)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount8"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount8)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes9"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes9)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName9"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName9)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime9"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime9)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated9"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated9)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber9"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber9)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg9"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg9)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg9"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg9)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT9"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT9)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT9"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT9)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT9"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT9)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT9"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT9)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount9"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount9)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes10"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes10)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName10"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName10)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime10"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime10)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated10"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated10)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber10"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber10)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg10"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg10)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg10"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg10)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT10"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT10)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT10"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT10)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT10"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT10)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT10"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT10)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount10"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount10)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes11"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes11)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName11"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName11)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime11"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime11)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated11"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated11)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber11"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber11)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg11"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg11)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg11"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg11)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT11"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT11)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT11"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT11)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT11"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT11)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT11"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT11)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount11"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount11)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes12"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes12)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName12"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName12)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime12"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime12)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated12"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated12)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber12"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber12)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg12"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg12)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg12"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg12)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT12"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT12)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT12"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT12)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT12"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT12)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT12"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT12)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount12"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount12)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes13"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes13)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName13"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName13)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime13"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime13)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated13"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated13)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber13"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber13)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg13"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg13)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg13"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg13)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT13"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT13)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT13"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT13)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT13"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT13)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT13"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT13)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount13"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount13)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes14"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes14)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName14"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName14)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime14"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime14)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated14"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated14)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber14"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber14)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg14"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg14)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg14"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg14)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT14"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT14)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT14"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT14)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT14"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT14)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT14"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT14)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount14"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount14)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes15"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes15)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName15"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName15)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime15"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime15)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated15"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated15)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber15"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber15)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg15"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg15)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg15"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg15)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT15"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT15)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT15"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT15)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT15"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT15)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT15"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT15)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount15"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount15)}
                                        />
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes16"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes16)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName16"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName16)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime16"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime16)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated16"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated16)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber16"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber16)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg16"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg16)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg16"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg16)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT16"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT16)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT16"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT16)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT16"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT16)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT16"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT16)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount16"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount16)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes17"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes17)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName17"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName17)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime17"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime17)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated17"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated17)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber17"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber17)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg17"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg17)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg17"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg17)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT17"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT17)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT17"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT17)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT17"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT17)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT17"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT17)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount17"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount17)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes18"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes18)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName18"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName18)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime18"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime18)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated18"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated18)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber18"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber18)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg18"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg18)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg18"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg18)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT18"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT18)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT18"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT18)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT18"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT18)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT18"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT18)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount18"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount18)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes19"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes19)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName19"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName19)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime19"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime19)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated19"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated19)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber19"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber19)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg19"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg19)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg19"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg19)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT19"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT19)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT19"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT19)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT19"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT19)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT19"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT19)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount19"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount19)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes20"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes20)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName20"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName20)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime20"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime20)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated20"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated20)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber20"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber20)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg20"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg20)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg20"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg20)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT20"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT20)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT20"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT20)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT20"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT20)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT20"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT20)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount20"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount20)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes21"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes21)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName21"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName21)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime21"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime21)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated21"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated21)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber21"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber21)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg21"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg21)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg21"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg21)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT21"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT21)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT21"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT21)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT21"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT21)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT21"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT21)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount21"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount21)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborCostCodes22"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborCostCodes22)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborName22"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborName22)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborTime22"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborTime22)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentOperated22"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentOperated22)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborEquipmentNumber22"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborEquipmentNumber22)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborReg22"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborReg22)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateReg22"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateReg22)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborOT22"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborOT22)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateOT22"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateOT22)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborDT22"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborDT22)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="LaborRateDT22"
                                            onChange={onChange}
                                            value={showVar(entry.data.LaborRateDT22)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="LaborAmount22"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.LaborAmount22)}
                                        />
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                        <table
                            id="equipmentRentals"
                            cellPadding={0}
                            cellSpacing={0}
                            style={{
                                border: "0 none",
                                borderRight: "1px solid #000",
                                borderBottom: "3px solid #000",
                                width: "100%"
                            }}
                        >
                            <tbody>
                                <tr>
                                    <th
                                        id="EquipmentRentalsCostCodes"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "10%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Cost Codes
                                    </th>
                                    <th
                                        id="EquipmentRentalsCo"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "25%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Co.
                                    </th>
                                    <th
                                        id="EquipmentRentalsType"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Type
                                    </th>
                                    <th
                                        id="EquipmentRentalsSerialEquipmentNumber"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "17%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Serial/Equip #
                                    </th>
                                    <th
                                        id="EquipmentRentalsDay"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Day
                                    </th>
                                    <th
                                        id="EquipmentRentalsRate"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Rate
                                    </th>
                                    <th
                                        id="EquipmentRentalsAmount"
                                        className="bold"
                                        data-calculation="([EquipmentRentalsDay]*[EquipmentRentalsRate])"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "bold !important",
                                            textAlign: "center",
                                            fontSize: 11,
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000"
                                        }}
                                    >
                                        Amount
                                    </th>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsCostCodes1"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsCostCodes1)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsCo1"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsCo1)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsType1"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsType1)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsSerialEquipmentNumber1"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsSerialEquipmentNumber1)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsDay1"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsDay1)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsRate1"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsRate1)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="EquipmentRentalsAmount1"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.EquipmentRentalsAmount1)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsCostCodes2"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsCostCodes2)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsCo2"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsCo2)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsType2"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsType2)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsSerialEquipmentNumber2"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsSerialEquipmentNumber2)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsDay2"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsDay2)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsRate2"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsRate2)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="EquipmentRentalsAmount2"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.EquipmentRentalsAmount2)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsCostCodes3"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsCostCodes3)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsCo3"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsCo3)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsType3"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsType3)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsSerialEquipmentNumber3"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsSerialEquipmentNumber3)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsDay3"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsDay3)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsRate3"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsRate3)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="EquipmentRentalsAmount3"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.EquipmentRentalsAmount3)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsCostCodes4"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsCostCodes4)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsCo4"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsCo4)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsType4"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsType4)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsSerialEquipmentNumber4"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsSerialEquipmentNumber4)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsDay4"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsDay4)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsRate4"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsRate4)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="EquipmentRentalsAmount4"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.EquipmentRentalsAmount4)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsCostCodes5"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsCostCodes5)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsCo5"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsCo5)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsType5"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsType5)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsSerialEquipmentNumber5"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsSerialEquipmentNumber5)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsDay5"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsDay5)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsRate5"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsRate5)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="EquipmentRentalsAmount5"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.EquipmentRentalsAmount5)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsCostCodes6"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsCostCodes6)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsCo6"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsCo6)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsType6"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsType6)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsSerialEquipmentNumber6"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsSerialEquipmentNumber6)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsDay6"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsDay6)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsRate6"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsRate6)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="EquipmentRentalsAmount6"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.EquipmentRentalsAmount6)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsCostCodes7"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsCostCodes7)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsCo7"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsCo7)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsType7"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsType7)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsSerialEquipmentNumber7"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsSerialEquipmentNumber7)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsDay7"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsDay7)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="EquipmentRentalsRate7"
                                            onChange={onChange}
                                            value={showVar(entry.data.EquipmentRentalsRate7)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="EquipmentRentalsAmount7"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.EquipmentRentalsAmount7)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            id="hauling"
                            cellPadding={0}
                            cellSpacing={0}
                            style={{
                                border: "0 none",
                                borderRight: "1px solid #000",
                                borderBottom: "3px solid #000",
                                width: "100%"
                            }}
                        >
                            <tbody>
                                <tr>
                                    <th
                                        id="HaulingCostCodes"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "10%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Cost Codes
                                    </th>
                                    <th
                                        id="HaulingCo"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "18%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Co.
                                    </th>
                                    <th
                                        id="HaulingTruckType"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "14%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Truck Type
                                    </th>
                                    <th
                                        id="HaulingMaterialType"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "14%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Material Type
                                    </th>
                                    <th
                                        id="HaulingLoads"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "8%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Loads
                                    </th>
                                    <th
                                        id="HaulingTons"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "8%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Tons
                                    </th>
                                    <th
                                        id="HaulingHours"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "8%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Hours
                                    </th>
                                    <th
                                        id="HaulingRate"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "8%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Rate
                                    </th>
                                    <th
                                        id="HaulingAmount"
                                        className="bold"
                                        data-calculation="([HaulingLoads]*[HaulingRate])+([HaulingTons]*[HaulingRate])+([HaulingHours]*[HaulingRate])"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "bold !important",
                                            textAlign: "center",
                                            fontSize: 11,
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000"
                                        }}
                                    >
                                        Amount
                                    </th>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingCostCodes1"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingCostCodes1)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingCo1"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingCo1)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingTruckType1"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingTruckType1)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingMaterialType1"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingMaterialType1)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingLoads1"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingLoads1)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingTons1"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingTons1)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingHours1"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingHours1)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingRate1"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingRate1)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="HaulingAmount1"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.HaulingAmount1)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingCostCodes2"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingCostCodes2)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingCo2"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingCo2)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingTruckType2"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingTruckType2)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingMaterialType2"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingMaterialType2)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingLoads2"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingLoads2)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingTons2"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingTons2)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingHours2"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingHours2)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingRate2"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingRate2)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="HaulingAmount2"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.HaulingAmount2)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingCostCodes3"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingCostCodes3)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingCo3"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingCo3)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingTruckType3"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingTruckType3)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingMaterialType3"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingMaterialType3)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingLoads3"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingLoads3)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingTons3"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingTons3)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingHours3"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingHours3)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingRate3"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingRate3)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="HaulingAmount3"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.HaulingAmount3)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingCostCodes4"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingCostCodes4)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingCo4"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingCo4)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingTruckType4"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingTruckType4)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingMaterialType4"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingMaterialType4)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingLoads4"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingLoads4)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingTons4"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingTons4)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingHours4"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingHours4)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingRate4"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingRate4)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="HaulingAmount4"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.HaulingAmount4)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingCostCodes5"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingCostCodes5)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingCo5"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingCo5)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingTruckType5"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingTruckType5)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingMaterialType5"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingMaterialType5)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingLoads5"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingLoads5)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingTons5"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingTons5)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingHours5"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingHours5)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingRate5"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingRate5)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="HaulingAmount5"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.HaulingAmount5)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingCostCodes6"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingCostCodes6)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingCo6"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingCo6)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingTruckType6"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingTruckType6)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingMaterialType6"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingMaterialType6)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingLoads6"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingLoads6)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingTons6"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingTons6)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingHours6"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingHours6)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingRate6"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingRate6)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="HaulingAmount6"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.HaulingAmount6)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingCostCodes7"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingCostCodes7)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingCo7"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingCo7)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingTruckType7"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingTruckType7)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingMaterialType7"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingMaterialType7)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingLoads7"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingLoads7)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingTons7"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingTons7)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingHours7"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingHours7)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="HaulingRate7"
                                            onChange={onChange}
                                            value={showVar(entry.data.HaulingRate7)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="HaulingAmount7"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.HaulingAmount7)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            id="suppliesAndMaterials"
                            cellPadding={0}
                            cellSpacing={0}
                            style={{
                                border: "0 none",
                                borderRight: "1px solid #000",
                                borderBottom: "3px solid #000",
                                width: "100%"
                            }}
                        >
                            <tbody>
                                <tr>
                                    <th
                                        id="SuppliesAndMaterialsCostCodes"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "10%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Cost Codes
                                    </th>
                                    <th
                                        id="SuppliesAndMaterialsName"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "48%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Name
                                    </th>
                                    <th
                                        id="SuppliesAndMaterialsQuantity"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "14%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Quantity
                                    </th>
                                    <th
                                        id="SuppliesAndMaterialsUnit"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "8%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Unit
                                    </th>
                                    <th
                                        id="SuppliesAndMaterialsPrice"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            width: "8%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 11
                                        }}
                                    >
                                        Price
                                    </th>
                                    <th
                                        id="SuppliesAndMaterialsAmount"
                                        className="bold"
                                        data-calculation="[SuppliesAndMaterialsQuantity]*[SuppliesAndMaterialsPrice]"
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "bold !important",
                                            textAlign: "center",
                                            fontSize: 11,
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000"
                                        }}
                                    >
                                        Amount
                                    </th>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsCostCodes1"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsCostCodes1)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsName1"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsName1)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsQuantity1"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsQuantity1)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsUnit1"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsUnit1)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsPrice1"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsPrice1)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="SuppliesAndMaterialsAmount1"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.SuppliesAndMaterialsAmount1)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsCostCodes2"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsCostCodes2)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsName2"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsName2)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsQuantity2"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsQuantity2)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsUnit2"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsUnit2)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsPrice2"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsPrice2)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="SuppliesAndMaterialsAmount2"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.SuppliesAndMaterialsAmount2)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsCostCodes3"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsCostCodes3)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsName3"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsName3)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsQuantity3"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsQuantity3)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsUnit3"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsUnit3)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsPrice3"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsPrice3)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="SuppliesAndMaterialsAmount3"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.SuppliesAndMaterialsAmount3)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsCostCodes4"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsCostCodes4)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsName4"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsName4)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsQuantity4"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsQuantity4)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsUnit4"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsUnit4)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsPrice4"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsPrice4)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="SuppliesAndMaterialsAmount4"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.SuppliesAndMaterialsAmount4)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsCostCodes5"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsCostCodes5)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsName5"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsName5)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsQuantity5"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsQuantity5)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsUnit5"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsUnit5)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsPrice5"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsPrice5)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="SuppliesAndMaterialsAmount5"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.SuppliesAndMaterialsAmount5)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsCostCodes6"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsCostCodes6)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsName6"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsName6)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsQuantity6"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsQuantity6)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsUnit6"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsUnit6)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsPrice6"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsPrice6)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="SuppliesAndMaterialsAmount6"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.SuppliesAndMaterialsAmount6)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsCostCodes7"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsCostCodes7)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsName7"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsName7)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsQuantity7"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsQuantity7)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsUnit7"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsUnit7)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsPrice7"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsPrice7)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="SuppliesAndMaterialsAmount7"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.SuppliesAndMaterialsAmount7)}
                                        />
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsCostCodes8"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsCostCodes8)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsName8"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsName8)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsQuantity8"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsQuantity8)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsUnit8"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsUnit8)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsPrice8"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsPrice8)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsAmount8"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.SuppliesAndMaterialsAmount8)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsCostCodes9"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsCostCodes9)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsName9"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsName9)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsQuantity9"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsQuantity9)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsUnit9"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsUnit9)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsPrice9"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsPrice9)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsAmount9"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.SuppliesAndMaterialsAmount9)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsCostCodes10"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsCostCodes10)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsName10"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsName10)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsQuantity10"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsQuantity10)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsUnit10"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsUnit10)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsPrice10"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsPrice10)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsAmount10"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.SuppliesAndMaterialsAmount10)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsCostCodes11"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsCostCodes11)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsName11"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsName11)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsQuantity11"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsQuantity11)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsUnit11"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsUnit11)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsPrice11"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsPrice11)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsAmount11"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.SuppliesAndMaterialsAmount11)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsCostCodes12"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsCostCodes12)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsName12"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsName12)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsQuantity12"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsQuantity12)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsUnit12"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsUnit12)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsPrice12"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsPrice12)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsAmount12"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.SuppliesAndMaterialsAmount12)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsCostCodes13"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsCostCodes13)}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsName13"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsName13)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsQuantity13"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsQuantity13)}
                                        />
                                    </td>
                                    <td
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsUnit13"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsUnit13)}
                                        />
                                    </td>
                                    <td
                                        className="right calc"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsPrice13"
                                            onChange={onChange}
                                            value={showVar(entry.data.SuppliesAndMaterialsPrice13)}
                                        />
                                    </td>
                                    <td
                                        className="right total"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "bottom",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="SuppliesAndMaterialsAmount13"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.SuppliesAndMaterialsAmount13)}
                                        />
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                        <table
                            id="totals"
                            cellPadding={0}
                            cellSpacing={0}
                            style={{
                                border: "0 none",
                                borderRight: "1px solid #000",
                                borderBottom: "3px solid #000",
                                width: "100%",
                                height: 35
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="remarks"
                                        className="bold"
                                        style={{
                                            verticalAlign: "middle",
                                            textTransform: "uppercase",
                                            fontSize: 10,
                                            width: "10%",
                                            textAlign: "center",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontWeight: "bold !important"
                                        }}
                                    >
                                        Remarks
                                    </td>
                                    <td
                                        id="remarks-field"
                                        style={{
                                            verticalAlign: "middle",
                                            width: "70%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            className="change-textfield"
                                            name="Remarks"
                                            onChange={onChange}
                                            value={showVar(entry.data.Remarks)}
                                        />
                                    </td>
                                    <td
                                        id="total"
                                        className="bold"
                                        style={{
                                            verticalAlign: "middle",
                                            textTransform: "uppercase",
                                            fontSize: 10,
                                            width: "8%",
                                            textAlign: "center",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontWeight: "bold !important"
                                        }}
                                    >
                                        Total
                                    </td>
                                    <td
                                        id="total-field"
                                        className="right"
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "middle",
                                            width: "12%",
                                            borderLeft: "1px solid #000",
                                            borderTop: "1px solid #000",
                                            fontSize: 10
                                        }}
                                    >
                                        <Input
                                            // disabled
                                            className="change-textfield bg-white"
                                            name="Total"
                                            onChange={(e) => {
                                                let tempObj = { ...entry.data };
                                                tempObj[e.target.name] = e.target.value;
                                                setEntry({ ...entry, data: tempObj });
                                                setIsAutomaticallyCalculating(false);
                                            }}
                                            onBlur={onBlur}
                                            value={showVar(entry.data.Total)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            id="signatures"
                            cellPadding={0}
                            cellSpacing={0}
                            style={{ border: "0 none", width: "85%", margin: "0 auto" }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        id="authorizedBySignature-field"
                                        className="center textfield"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "40%"
                                        }}
                                    >
                                        {entry.data.AuthorizedBySignature != null && entry.data.AuthorizedBySignature !== '' &&
                                            <img src={entry.data.AuthorizedBySignature} alt="Authorized By Signature" className='signatureImagePreview' />}
                                    </td>
                                    <td
                                        className="spacer"
                                        style={{ verticalAlign: "bottom", width: "20%" }}
                                    >
                                        &nbsp;
                                    </td>
                                    <td
                                        id="foremanSignature-field"
                                        className="center textfield"
                                        style={{
                                            textAlign: "center",
                                            verticalAlign: "bottom",
                                            borderBottom: "1px solid #000",
                                            marginRight: "5%",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            width: "40%",
                                            height: "50px"
                                        }}
                                    >
                                        {entry.data.ForemanSignature != null && entry.data.ForemanSignature !== '' &&
                                            <img src={entry.data.ForemanSignature}
                                                alt="Foreman Signature"
                                                className='signatureImagePreview' />}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        className="center uppercase"
                                        style={{
                                            textAlign: "center",
                                            textTransform: "uppercase",
                                            verticalAlign: "bottom"
                                        }}
                                    >
                                        Authorized By
                                    </td>
                                    <td
                                        className="spacer"
                                        style={{ verticalAlign: "bottom", width: "20%" }}
                                    >
                                        &nbsp;
                                    </td>
                                    <td
                                        className="center uppercase"
                                        style={{
                                            textAlign: "center",
                                            textTransform: "uppercase",
                                            verticalAlign: "bottom"
                                        }}
                                    >
                                        Foreman
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default DailyReportPreviewEdit;