import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Label, Input, Nav, NavItem, NavLink, TabPane, TabContent, Table, Button } from 'reactstrap';
import classnames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import CanvasModal from '../components/Canvas/CanvasModal';
import DailyReportPreview from '../components/Preview/DailyReportPreview';
import ConfirmButton from '../components/ConfirmButton/ConfirmButton';
import * as utilities from '../utilities';


const DailyReport = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [showPreview, setShowPreview] = useState(false);
    const [user, setUser] = useState(localStorage.getItem('user') != null ? localStorage.getItem('user') : '');
    const [email, setEmail] = useState(localStorage.getItem('email') != null ? localStorage.getItem('email') : '');

    const [stateVariables, setStateVariables] = useState(localStorage.getItem('stateVariables') != null ? JSON.parse(localStorage.getItem('stateVariables')) : {});

    const [config, setConfig] = useState(localStorage.getItem('config') != null ? JSON.parse(localStorage.getItem('config')) : []);
    const [fields, setFields] = useState(localStorage.getItem('fields') != null ? JSON.parse(localStorage.getItem('fields')) : []);
    const [entries, setEntries] = useState(localStorage.getItem('entries') != null ? JSON.parse(localStorage.getItem('entries')) : []);

    const customSelectStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '42px',
            height: '42px',
            borderRadius: 0,
            border: '0px solid #dee2e6',
            backgroundColor: '#000',
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '42px', // Adjust height
            padding: '0 6px', // Adjust padding to align text if necessary
            color: '#fff'
        }),
        input: (provided) => ({
            ...provided,
            padding: '0',
            margin: '0px', // Remove default margins

        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: '42px', // Adjust the height of indicators (like dropdown arrow)
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: '5px', // Adjust padding of the dropdown indicator icon
        }),
        clearIndicator: (provided) => ({
            ...provided,
            padding: '5px', // Adjust padding of the clear indicator icon
        }),
        indicatorSeparator: () => ({
            display: 'none', // This hides the indicator separator
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999, // Ensure the menu is above other elements
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: 150, // Set a max height for the menu
            overflowY: 'auto',
            backgroundColor: '#333'
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#666' : '#333',
            color: 'white',
            '&:hover': {
                backgroundColor: '#222',
            }
        }),
        input: (provided) => ({
            ...provided,
            color: 'white'
        })
    };

    const generateRandomHex = () => {
        const randomHex = Math.floor(Math.random() * 0xFFFFFFFF).toString(16).toUpperCase();
        return randomHex.padStart(8, '0');
    }

    const initializeStateVariables = (force) => {
        if (stateVariables[props.config] == null || force) {
            const initialState = {
                JobNumber: '',
                JobName: '',
                Date: new Date().toISOString().substring(0, 10),
                County: '',
                BillTo: '',
                DescriptionOfWork: '',
                RFCO: '',
                Remarks: '',
                Total: '',
                AuthorizedBySignature: '',
                ForemanSignature: '',
                Name: '',
                Email: '',
                _TransactionCode: generateRandomHex(),
                _Timestamp: null,
                _Submitted: false
            };
            fields[props.config] != null && fields[props.config].forEach(section => {
                new Array(section.rows).fill(null).forEach((row, rowIndex) => {
                    section.fields.forEach(field => {
                        initialState[`${section.name}${field.name}${rowIndex + 1}`] = field.default;
                    })
                })
            })
            setStateVariables({ [props.config]: initialState });
            let obj = {};
            if (localStorage.getItem('stateVariables') != null) {
                obj = JSON.parse(localStorage.getItem('stateVariables'));
            };
            obj[props.config] = initialState;
            localStorage.setItem('stateVariables', JSON.stringify(obj))
        }
    };

    const handleInputChange = (key, e) => {
        // Use the spread operator to create a new state object
        let tempStateVariables = { ...stateVariables[props.config] };
        tempStateVariables[key] = e.target.value;
        let totalArr = [];
        let totalAmount = 0;
        fields[props.config].forEach(section => {
            new Array(section.rows).fill(null).forEach((row, rowIndex) => {
                section.fields.filter(field => field.type === 'total').forEach(field => {
                    const tempTotal = getCalculation(tempStateVariables, field.calculation, section.name, rowIndex + 1)
                    totalArr[`${section.name}${field.name}${rowIndex + 1}`] = tempTotal;
                    if (!isNaN(parseFloat(String(tempTotal).replace(/^[^\d]*/, '')))) {
                        totalAmount += parseFloat(String(tempTotal).replace(/[^\d\.]*/g, ''));
                    }
                })
            })
        })
        setStateVariables((prev) => {
            let obj = { ...prev };
            obj[props.config].Total = totalAmount > 0 ? totalAmount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD', // Change to your desired currency code
            }) : '';
            obj[props.config][key] = String(e.target.value) === '0' ? '' : e.target.value;
            obj[props.config] = { ...obj[props.config], ...totalArr }
            return {
                ...obj
            }
        }
        );
    };

    const fetchConfig = async () => {
        const response = await fetch(props.configLink, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + utilities.getAccessToken()
            }
        })
        setIsLoading(false);
        if (response.ok) {
            const result = await response.json();

            const resultConfig = JSON.parse(result.config);
            let configObj = {};
            if (localStorage.getItem('config') != null) {
                configObj = JSON.parse(localStorage.getItem('config'));
            };
            configObj = { ...configObj, [props.config]: resultConfig };
            setConfig(configObj);
            localStorage.setItem('config', JSON.stringify(configObj));

            const resultFields = JSON.parse(result.fields);
            let fieldsObj = {};
            if (localStorage.getItem('fields') != null) {
                fieldsObj = JSON.parse(localStorage.getItem('fields'));
            };
            fieldsObj = { ...fieldsObj, [props.config]: resultFields };
            setFields(fieldsObj);
            localStorage.setItem('fields', JSON.stringify(fieldsObj));

            if (localStorage.getItem('stateVariables') == null) initializeStateVariables();
        } else {
            utilities.addToast('Unable to load latest configuration', 5, 'warning');
        }

    };

    const displayInputValue = (val) => {
        return (val != null) ? val : '';
    }

    const displayInput = (tab, field, row) => {
        switch (field.type) {
            case "number":
                return (<Input
                    className={'m-0 p-1'}
                    style={{ textAlign: field.alignment }}
                    type={field.type}
                    name={field.name}
                    min={field.min}
                    max={field.max}
                    step={field.step}
                    value={displayInputValue(stateVariables[props.config][`${tab}${field.name}${row}`])}
                    onChange={(e) => handleInputChange(`${tab}${field.name}${row}`, e)}
                />);
            case "select":
                return (
                    <CreatableSelect
                        className={'m-0 p-0 rounded-0 ' + (field.className || '')}
                        menuPlacement='auto'
                        menuPosition='fixed'
                        styles={customSelectStyles}
                        name={field.name}
                        onChange={(e) => handleInputChange(`${tab}${field.name}${row}`, { target: e })}
                        value={{
                            value: displayInputValue(stateVariables?.[props.config]?.[`${tab}${field.name}${row}`]),
                            label: displayInputValue(stateVariables?.[props.config]?.[`${tab}${field.name}${row}`])
                        }}
                        options={[
                            { value: 0, label: 'SELECT' }, // This is the blank option
                            ...config[props.config][field.optionValues].map(option => ({
                                value: option,
                                label: option
                            }))
                        ]}
                    />
                )
            // return (<Input
            //     className={'m-0 p-1'}
            //     style={{ textAlign: field.alignment }}
            //     type={field.type}
            //     name={field.name}
            //     value={displayInputValue(stateVariables[props.config][`${tab}${field.name}${row}`])}
            //     onChange={(e) => handleInputChange(`${tab}${field.name}${row}`, e)}
            // >
            //     <option value=""></option>
            //     {field.type === 'select' && config[props.config][field.optionValues].map(option => (
            //         <option key={option}>{option}</option>
            //     ))}
            // </Input>);
            case "total":
                return (<Input
                    className={'m-0 p-1'}
                    style={{ textAlign: field.alignment }}
                    type={field.type}
                    name={field.name}
                    disabled={field.disabled}
                    // value={getCalculation(field.calculation, tab, row)}
                    value={displayInputValue(stateVariables[props.config][`${tab}${field.name}${row}`])}
                // onChange={(e) => handleInputChange(`${tab}${field.name}${row}`, e)}
                />);
            default:
                return (<Input
                    className={'m-0 p-1'}
                    style={{ textAlign: field.alignment }}
                    type={field.type}
                    name={field.name}
                    value={displayInputValue(stateVariables[props.config][`${tab}${field.name}${row}`])}
                    onChange={(e) => handleInputChange(`${tab}${field.name}${row}`, e)}
                />)
        }
    }

    const getCalculation = (tempStateVariables, expression, tab, row) => {
        expression = expression.replaceAll('[', `[${tab}`)
        expression = expression.replaceAll(']', `${row}]`);
        const variableNames = expression.match(/\[([^\]]+)\]/g);
        if (variableNames) {
            let result = expression;

            variableNames.forEach((variable) => {
                const variableKey = variable.substring(1, variable.length - 1); // Remove brackets
                const variableValue = tempStateVariables[variableKey] || 0; // Use 0 if variable not found
                result = result.replace(variable, variableValue);
            });
            // Evaluate the final expression
            try {
                // eslint-disable-next-line no-eval
                result = eval(result);
                return result === 0 ? '' : result.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD', // Change to your desired currency code
                });
            } catch (error) {
                console.error('Error evaluating expression:', error);
                return null;
            }
        }

        return null;
    }

    const saveEntry = () => {
        let obj = {};
        if (localStorage.getItem('entries') != null) {
            obj = JSON.parse(localStorage.getItem('entries'));
        };
        if (obj[props.config] == null) obj[props.config] = [];
        stateVariables[props.config]['_Timestamp'] = (new Date().getTime()) / 1000;
        stateVariables[props.config]['_User'] = user;
        stateVariables[props.config]['_Email'] = email;
        obj[props.config].push(Object.entries(stateVariables[props.config]).reduce((acc, [key, val]) => {
            if ((val != null && val !== '') || String(key).substring(0, 1) === '_') acc[key] = val;
            return acc;
        }, {}));
        localStorage.setItem('entries', JSON.stringify(obj));
        setEntries(obj);
        initializeStateVariables(true);
    }

    const submitEntries = async () => {
        entries[props.config] && entries[props.config].filter(entry => !entry['_Submitted']).forEach(async entry => {
            const response = await fetch(props.submitLink, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + utilities.getAccessToken()
                },
                body: JSON.stringify(entry)
            })
            if (response.ok) {
                utilities.addToast('Submission successful', 5, 'success');
                const result = await response.json();
                let obj = { ...entries };
                const entryIndex = obj[props.config].findIndex(entry => entry['_TransactionCode'] === result.transactionCode && !entry['_Submitted']);
                if (entryIndex > -1) {
                    obj[props.config][entryIndex]['_Submitted'] = true;
                    setEntries(obj);
                    localStorage.setItem('entries', JSON.stringify(obj));
                }
            } else {
                utilities.addToast('Submission unsuccessful, however the data is being stored on your device and will be uploaded once connected.', 5, 'danger')
            }
        })
    }

    // useEffect can be used for componentDidMount logic.
    useEffect(() => {
        fetchConfig();
    }, []); // empty dependency array ensures the effect runs only once after the initial render

    useEffect((e) => {
        initializeStateVariables();
    }, [props.config, fields]);

    useEffect((e) => {
        localStorage.setItem('user', user)
    }, [user]);

    useEffect((e) => {
        submitEntries();
    }, [entries]);

    useEffect((e) => {
        localStorage.setItem('email', email)
    }, [email]);

    useEffect((e) => {
        let obj = {};
        if (localStorage.getItem('stateVariables') != null) {
            obj = JSON.parse(localStorage.getItem('stateVariables'));
        };
        obj = { ...obj, ...stateVariables };
        localStorage.setItem('stateVariables', JSON.stringify(obj))
    }, [stateVariables]);

    return (
        <>
            {!isLoading && <div className='tablet px-2'>
                {showPreview && <DailyReportPreview
                    logo={props.logo}
                    title={props.title}
                    {...stateVariables}
                    isRotated={true}
                    hidePreview={() => setShowPreview(false)}
                    {...stateVariables[props.config]}
                />}
                <Form>
                    <Row className='m-0 p-0  align-items-end '>
                        <Col xs={4} className='m-0 p-0 pe-3'>
                            <Row>
                                <Col className='m-0'>
                                    <img id="logo" src={props.logo} alt={props.title} onClick={props.onLogoClick} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={5} className='m-0 pe-1' id="userEmail">
                                    <Input
                                        bsSize='sm'
                                        className='border-primary p-1'
                                        id="user"
                                        name="user"
                                        placeholder="User"
                                        value={user}
                                        onChange={(e) => setUser(e.target.value)}
                                    />
                                </Col>
                                <Col xs={5} className='m-0 pe-3 ps-0' id="userEmail">
                                    <Input
                                        bsSize='sm'
                                        className='border-primary p-1'
                                        id="email"
                                        name="email"
                                        placeholder='Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Col>
                                {entries[props.config] != null && entries[props.config].filter(entry => !entry['_Submitted']).length > 0 && <Col xs={2} className='m-0 pe-3 ps-0 align-self-center'>
                                    <div id="entriesCount">{entries[props.config].filter(entry => !entry['_Submitted']).length}</div>
                                </Col>}
                            </Row>
                        </Col>
                        <Col className='m-0 p-0'>
                            <Row className='row-cols-lg-auto g-3 align-items-center mt-2 mb-0'>

                                <Col className='m-0'>
                                    <Label
                                        for="JobNumber"
                                        className=''
                                    >
                                        Job#
                                    </Label>
                                </Col>
                                <Col className='w-25 m-0'>
                                    <Input
                                        // bsSize='lg'
                                        className='border-dark'
                                        id="JobNumber"
                                        name="JobNumber"
                                        value={stateVariables[props.config].JobNumber}
                                        onChange={(e) => handleInputChange(e.target.name, e)}
                                    />
                                </Col>
                                <Col className='m-0'>
                                    <Label for="JobName">
                                        Job Name
                                    </Label>
                                </Col>
                                <Col className='flex-grow-1 m-0'>
                                    <Input
                                        // bsSize='lg'
                                        className='border-dark'
                                        id="JobName"
                                        name="JobName"
                                        value={stateVariables[props.config].JobName}
                                        onChange={(e) => handleInputChange(e.target.name, e)}
                                    />
                                </Col>
                            </Row>

                            <Row className='row-cols-lg-auto g-3 align-items-center mt-2 mb-0'>

                                <Col className='m-0 pr-0'>
                                    <Label
                                        for="Date">
                                        Date
                                    </Label>
                                </Col>
                                <Col className='w-25 m-0'>
                                    <Input
                                        // bsSize='lg'
                                        className='border-dark'
                                        type="date"
                                        id="Date"
                                        name="Date"
                                        value={stateVariables[props.config].Date}
                                        onChange={(e) => handleInputChange(e.target.name, e)}
                                    />
                                </Col>
                                <Col className='m-0 pr-0'>
                                    <Label for="BillTo">
                                        Bill To
                                    </Label>
                                </Col>
                                <Col className='flex-grow-1 m-0 pl-0'>
                                    <Input
                                        // bsSize='lg'
                                        className='border-dark'
                                        id="BillTo"
                                        name="BillTo"
                                        value={stateVariables[props.config].BillTo}
                                        onChange={(e) => handleInputChange(e.target.name, e)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='row-cols-lg-auto g-3 align-items-center mt-2 mb-0'>
                        <Col className='m-0'>
                            <Label
                                for="County">
                                County
                            </Label>
                        </Col>
                        <Col className='flex-shrink-1 m-0'>
                            <Input
                                // bsSize='lg'
                                className='border-dark'
                                id="County"
                                name="County"
                                value={stateVariables[props.config].County}
                                onChange={(e) => handleInputChange(e.target.name, e)}
                            />
                        </Col>
                        <Col className='m-0'>
                            <Label
                                for="DescriptionOfWork">
                                Description of Work
                            </Label>
                        </Col>
                        <Col className='flex-grow-1 m-0'>
                            <Input
                                // bsSize='lg'
                                className='border-dark'
                                id="DescriptionOfWork"
                                name="DescriptionOfWork"
                                value={stateVariables[props.config].DescriptionOfWork}
                                onChange={(e) => handleInputChange(e.target.name, e)}
                            />
                        </Col>
                        <Col className='m-0'>
                            <Label for="RFCO">
                                RFCO
                            </Label>
                        </Col>
                        <Col className='flex-shrink-1 m-0'>
                            <Input
                                // bsSize='lg'
                                className='border-dark'
                                id="RFCO"
                                name="RFCO"
                                value={stateVariables[props.config].RFCO}
                                onChange={(e) => handleInputChange(e.target.name, e)}
                            />
                        </Col>
                    </Row>

                    <Row className='row-cols-lg-auto g-3 align-items-center mt-2 mb-0' id="tabs">
                        <Col>
                            <Nav tabs className="pb-0 px-4">
                                {fields[props.config] != null && fields[props.config].map((section, sectionIndex) => (
                                    <NavItem key={sectionIndex}>
                                        <NavLink
                                            className={classnames({ active: activeTab === sectionIndex })}
                                            onClick={() => { setActiveTab(sectionIndex); }}>
                                            {section.title}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                        </Col>
                    </Row>

                    <Row className='g-3 align-items-center pt-2 mb-0' id="tabContent">
                        <Col className='p-0 m-0'>

                            <TabContent activeTab={activeTab} className="pt-2 px-0 mx-2">
                                {fields[props.config] != null && fields[props.config].map((section, sectionIndex) => (
                                    <TabPane tabId={sectionIndex} key={sectionIndex}>
                                        <Table className='m-0'>
                                            <thead>
                                                <tr>
                                                    {section.fields.map((field, fieldIndex) => (
                                                        <th key={fieldIndex} style={{ width: ((field.width / 1004) * 100) + '%' }}>{field.title}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {new Array(section.rows).fill(null).map((row, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {section.fields.map((field) => (
                                                            <td key={field.name} className='p-0'>
                                                                {displayInput(section.name, field, rowIndex + 1)}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </TabPane>
                                ))}
                            </TabContent>
                        </Col>
                    </Row>

                    <Row className='row-cols-lg-auto g-3 align-items-start mt-0 mb-0 pb-2' id="remarksTotal">
                        <Col className='flex-grow-1 m-0 mt-2 pe-5'>
                            <Input
                                // bsSize='lg'
                                className='border-dark'
                                type="textarea"
                                rows={2}
                                id="Remarks"
                                name="Remarks"
                                placeholder='Remarks'
                                value={stateVariables[props.config].Remarks}
                                onChange={(e) => handleInputChange(e.target.name, e)}
                            />
                        </Col>
                        <Col className='m-0 mt-2'>
                            <Label
                                className='text-end'
                                for="Total">
                                Total<br />
                                (All Sections)
                            </Label>
                        </Col>
                        <Col className='flex-shrink-1 m-0'>
                            <Input
                                // bsSize='lg'
                                style={{ textAlign: 'right' }}
                                id="Total"
                                name="Total"
                                value={stateVariables[props.config].Total}
                                onChange={(e) => handleInputChange(e.target.name, e)}
                            // disabled={true}
                            />
                        </Col>
                    </Row>
                </Form>

                <Row className='row-cols-lg-auto g-3 align-items-center mt-0 mb-0 pb-4' id="signaturesSubmit">
                    <Col className='col-lg-auto m-0 pe-0 position-relative'>
                        {stateVariables[props.config].AuthorizedBySignature !== '' &&
                            <img className="signatureImage" src={stateVariables[props.config].AuthorizedBySignature} alt="Authorized by Signature" />}
                        <CanvasModal
                            arrkey="AuthorizedBySignature"
                            saveimage={setStateVariables}
                            config={props.config}
                        // savePaths={setPathsAuthorizedBySignature}
                        // loadPaths={pathsAuthorizedBySignature}
                        >
                            <Input
                                className='border-dark'
                                id="AuthorizedBySignature"
                                name="AuthorizedBySignature"
                                readOnly={true}
                                style={{ width: 304, height: 60 }}
                                placeholder='Authorized By Signature'
                            />
                        </CanvasModal>
                    </Col>
                    <Col className='col-lg-auto m-0 position-relative'>
                        {stateVariables[props.config].ForemanSignature !== '' &&
                            <img className="signatureImage" src={stateVariables[props.config].ForemanSignature} alt="Foreman Signature" />}
                        <CanvasModal
                            arrkey="ForemanSignature"
                            saveimage={setStateVariables}
                            config={props.config}
                        // savePaths={setPathsForemanSignature}
                        // loadPaths={pathsForemanSignature}
                        >
                            <Input
                                className='border-dark'
                                id="ForemanSignature"
                                name="ForemanSignature"
                                readOnly={true}
                                style={{ width: 304, height: 60 }}
                                placeholder='Foreman Signature'
                            />
                        </CanvasModal>
                    </Col>
                    <Col className='flex-grow-1 m-0 p-0 ps-3 text-end'>
                        <ConfirmButton
                            color="danger"
                            className='px-4 me-2'
                            onClick={() => initializeStateVariables(true)}
                        >
                            Reset
                        </ConfirmButton>
                        {/* </Col>
                    <Col className='m-0 p-0 ps-2'> */}
                        <Button
                            color="primary"
                            className='px-4 me-2'
                            onClick={() => setShowPreview(true)}
                        >
                            Preview
                        </Button>
                        {/* </Col>
                    <Col className='m-0'> */}
                        <Button
                            color="success"
                            className='px-4 me-2'
                            onClick={saveEntry}
                            disabled={stateVariables[props.config].ForemanSignature === ''}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </div >}
        </>
    );
};

export default DailyReport;